import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import Button from "@/components/button";
import Input from "@/components/input";
import Table from "@/components/table";
import NeoTabsSwitch from "@/components/tabs-switch";
import Loader from "@/components/loader";
import {DateTime} from "luxon";
import OsintAxios from "@/axios/osint.js";
import rawAxios from "@/axios";
import NeoAnalyse from "@/components/analyse";
import Pagination from "@/components/pagination";
import neoSwitch from "@/components/toggle-switch";
import {getBackgroundStatus} from "@/utils/monitoringTools.js";
import {mapGetters} from "vuex";

export default {
    name: "domain-section",
    components: {
        "neo-input": Input,
        "neo-button": Button,
        "neo-table": Table,
        "neo-tabs-switch": NeoTabsSwitch,
        "font-awesome-icon": FontAwesomeIcon,
        "neo-loader": Loader,
        NeoAnalyse,
        neoPagination: Pagination,
        neoSwitch,
    },
    props: {
        domainData: {
            type: Array,
            default: "",
        },
        domain: {
            type: String,
            default: "",
        },
        isMonitoringTab: {
            type: Boolean,
            default: false,
        },
        estimatedDomainAge: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            // domainData: {},
            loaderController: {
                loading: false,
                success: false,
                filterLoading: false,
                filterSuccess: false,
            },
            // subdomains: [],
            // fetchedSubdomains: false,
            domainDetaisExpanded: false,
            case_id: "",
            // currentPage: 1,
            // perPage: 20,
            domainInfo: false,
            expandedPanel: false,
        };
    },
    computed: {
        ...mapGetters(["getProduct"]),
        // sortedSubdomains() {
        //     return this.subdomains.sort();
        // },
        // getTotalPages() {
        //     return Math.ceil(this.subdomains.length / this.perPage)
        // },
        // paginatedDomains() {
        //     return this.
        // }
    },
    methods: {
        getBackgroundStatus,
        // async fetchSubdomains() {
        //     const { data } = await OsintAxios.get(`/domain/subdomains/${this.domain}`);
        //     this.fetchedSubdomains = true;
        //     this.subdomains = data?.data;
        // },
        // handlePageChange(page ) {
        //     this.currentPage = page
        // },
        getFormattedTime(ts, format = "EEE, dd MMM yyyy, hh:mm:ss a") {
            let dt = DateTime.fromISO(ts);
            if (dt.toFormat(format) != "Invalid DateTime") return dt.toFormat(format);
            else return "";
        },
        startLoader() {
            this.loaderController.success = false;
            this.loaderController.loading = true;
        },
        successLoader() {
            this.loaderController.success = true;
            this.loaderController.loading = false;
        },
        resetLoader() {
            this.loaderController.success = false;
            this.loaderController.loading = false;
        },

        handlePanelExpand(details, value) {
            details.isexpanded = !details.isexpanded;
            this.$forceUpdate();
        },
        handleExpandAll() {
            this.expandedPanel = !this.expandedPanel;
            this.domainData.forEach((el, index) => {
                if (this.expandedPanel) el.isexpanded = true;
                else el.isexpanded = false;
            });
            this.$forceUpdate();
        },
    },
};
